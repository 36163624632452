import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { format } from 'date-fns';// Icons
import { Loader as LoaderWithMessage } from "../../../Common/Loader";
import "flatpickr/dist/flatpickr.css";

// icons
import { Boxes, PackagePlus, Loader, Search, Truck, PackageCheck, PackageX, Plus, RefreshCcw, MoreHorizontal, Trash2, Eye, FileEdit, Pencil } from 'lucide-react';
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";

import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";

// react-redux
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";


import { ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";
import { getCartSummary, getCarts } from "helpers/backend_helper";

const Carts = () => {
    const [activeTab, setActiveTab] = useState("1");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const selectDataList = createSelector(
        (state: any) => state.Ecommerce,
        (state) => ({
            dataList: state.orders
        })
    );

    const { dataList } = useSelector(selectDataList);
    const [data, setData] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();

    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    // Get Data
    useEffect(() => {
        onGetCarts();
    }, []);

    //
    // Delete Modal

    const onGetCarts = async () => {
        try {
            const response = await getCarts();
            //console.log("State Data-1", response.data);
            setData(response.data);
            setIsLoading(false);

        } catch (err) { }
    };

    const columns = useMemo(() => [
        {
            header: (
                <div className="flex items-center h-full">
                    <input id="CheckboxAll" className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />
                </div>
            ),
            enableSorting: false,
            id: "checkAll",
            cell: (cell: any) => {
                return (
                    <div className="flex items-center h-full">
                        <input id={"Checkbox" + cell.row.original.id} className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />
                    </div>
                );
            }
        },
        {
            header: "Customer Name",
            accessorKey: "userName",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <>
                    <Link to={"/cart-detail/" + cell.row.original.userId + "/" + cell.row.original.businessId}
                        className="transition-all duration-150 ease-linear order_id text-custom-500 hover:text-custom-600"
                        style={{ textTransform: 'capitalize' }}>{cell.getValue()}</Link>
                </>
            ),
        },
        {
            header: "Business Name",
            accessorKey: "businessName",
            enableColumnFilter: false,

        },
        {
            header: "Quantity",
            accessorKey: "quantity",
            enableColumnFilter: false,
        },
        {
            header: "Amount",
            accessorKey: "totalAmount",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <p>  {'₹ ' + cell.getValue()}</p>
            ),
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <div className="flex justify-start gap-2" >
                    <Link to={"/cart-detail/" + cell.row.original.userId + "/" + cell.row.original.businessId}
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-green-500 bg-green-100 hover:text-white hover:bg-green-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                    >
                        <Eye className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> </Link>
                </div>
            ),
        }
    ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Cart Lists' pageTitle='Ecommerce' />
            <ToastContainer closeButton={false} limit={1} />

            <div className="card" id="ordersTable">
                <div className="card-body">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
                        <div className="lg:col-span-3">
                            <div className="relative">
                                <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for ..." autoComplete="off" />
                                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                            </div>
                        </div>
                    </div>

                    {isLoading ? (
                        <LoaderWithMessage />
                    ) : (
                        <div className="!pt-1 card-body">
                            {data.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(data || [])}
                                    customPageSize={10}
                                    divclassName="mt-5 overflow-x-auto"
                                    tableclassName="w-full whitespace-nowrap"
                                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
                                    tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                                    PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                                />)
                                :
                                (<div className="noresult">
                                    <div className="py-6 text-center">
                                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                        <p className="mb-0 text-slate-500 dark:text-zink-200">Ops... We did not find any items in the cart for you search.</p>
                                    </div>
                                </div>)}
                        </div>
                    )}

                </div>
            </div>
        </React.Fragment>
    );
};

export default Carts;