import React, { useContext, useState } from "react";

// Formik validation
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";

// Image
import logoDark from "assets/images/logo-dark.png";
import withRouter from "Common/withRouter";
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import { Link } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { addMenu, addPermissions, addRoleMenu, getMenus, getMenusByRole, getResources, getUserPermissions, postLogin } from "helpers/backend_helper";
import { AppContext } from "context/AppContext";

import { Common } from "helpers/common";


const Login = (props: any) => {

    document.title = "Login | VastriFi";

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { setPermissions, setMenus,setUserName } = useContext(AppContext);


    const validation: any = useFormic({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: async (values: any) => {
            setIsLoading(true);

            try {
                let response: any;
                response = await postLogin({
                    email: values.email,
                    password: values.password,
                });

                localStorage.setItem("app-permissions", "[]");

                localStorage.setItem("token", response.data.authToken.replace('Bearer ', ''));
                localStorage.setItem("refreshToken", response.data.refreshToken.replace('Bearer ', ''));

                const roleId = response?.data?.userRoleId ?? 0;//response.data.userRole==="admin"?1 : 18;

                const common = new Common();

                setSuccess(true);
                if (response) {

                    localStorage.setItem("IDKS@ROLE", common.encrypt(response.data.userRole));
                    localStorage.setItem("user-name", response.data.fullName);
                    setUserName(response.data.fullName);

                    try {
                        const responsePermission = await getUserPermissions(response.data.userRole);
                        localStorage.setItem("app-permissions", JSON.stringify(responsePermission.data || []));
                        setPermissions(responsePermission.data || []);
                    } catch (error) {
                        localStorage.setItem("app-permissions", JSON.stringify([]));
                        setPermissions( []);
                    }

                    try {
                        const responseMenu = await getMenusByRole(roleId);
                        localStorage.setItem("app-menus", JSON.stringify(responseMenu.data || []));
                        setMenus(responseMenu.data || []);
                    } catch (error) {
                        localStorage.setItem("app-menus", JSON.stringify( []));
                        setMenus([]);
                    }

                    //  response = await getMenus();
                    //  console.log('Menu Response', response.data);


                    //  const p =
                    // {
                    //     "roleId": 25,
                    //     "menuId": 10,
                    //     "isActive": true
                    // };

                    // console.log('P - ', p);

                    // const res = await addRoleMenu(p);

                    // response.data.forEach(async (item: any, index: number) => {

                    //     const p =
                    //     {
                    //         "roleId": 1,
                    //         "menuId": item.id,
                    //         "isActive": true
                    //     };

                    //     console.log('P - ', p);

                    //     const res = await addRoleMenu(p);

                    // });


                    // {
                    //     "roleId" : 18,
                    //     "menuId" : 2,
                    //     "isActive" :  true
                    // }

                    // menuData.forEach(async (item: any, index: number) => {

                    //     const p = {
                    //         "name": item.label,
                    //         "url": item.link,
                    //         "icon": item.label + ".jpg",
                    //         "isActive": true
                    //     }
                    //     console.log('P - ' ,p);

                    //     try {


                    //     const resMenu = await addMenu(p);

                    //     console.log('P - ' , resMenu);

                    //     if (item?.subItems?.length > 0) {
                    //         item?.subItems.forEach(async (itemC: any, index: number) => {
                    //             const c = {
                    //                 "name": itemC.label,
                    //                 "url": itemC.link,
                    //                 "parentId": resMenu.data.rows[0].id,
                    //                 "icon": itemC.label + ".jpg",
                    //                 "isActive": true
                    //             }
                    //             console.log('C - ' ,c);
                    //             const resMenuC= await addMenu(c);
                    //             console.log('P - ' , resMenuC);

                    //             //console.log('C - ' + itemC.label);
                    //         })
                    //     }
                    // } catch (error) {
                    //     console.log('P - ' , error);
                    // }

                    // })

                    // const response2 = await getResources();
                    // response2.data.forEach(async (item: any, index: number) => {
                    //       await addPermissions({
                    //         role: "admin",
                    //         resource: item.name,
                    //         action: "menu:own",
                    //         attributes:  "* ",
                    //         isActive: true,
                    //       });

                    // });

                    setTimeout(() => {
                        props.router.navigate("/dashboard");
                    }, 1000)
                }

                setIsLoading(false);

            } catch (error) {
                setIsLoading(false);

                console.log(error);

                setError(error as string);
            }

        }
    });

    React.useEffect(() => {
        const bodyElement = document.body;

        bodyElement.classList.add('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');

        return () => {
            bodyElement.classList.remove('flex', 'items-center', 'justify-center', 'min-h-screen', 'py-16', 'lg:py-10', 'bg-slate-50', 'dark:bg-zink-800', 'dark:text-zink-100', 'font-public');
        }
    }, []);

    return (
        <React.Fragment>
            <div className="relative">
                <AuthIcon />

                <div className="mb-0 w-screen lg:mx-auto lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
                    <div className="!px-10 !py-12 card-body">
                        <Link to="/">
                            {/* <img src={logoLight} alt="" className="hidden h-6 mx-auto dark:block" /> */}
                            <img src={logoDark} alt="" className="block h-17 mx-auto dark:hidden" />
                        </Link>

                        <div className="mt-8 text-center">
                            <h4 className="mb-1 text-custom-500 dark:text-custom-500">Welcome Back !</h4>
                            <p className="text-slate-500 dark:text-zink-200">Sign in to continue to VastriFi.</p>
                        </div>

                        <form className="mt-10" id="signInForm"
                            onSubmit={(event: any) => {
                                event.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}>
                            {success && <div className="px-4 py-3 mb-3 text-sm text-green-500 border border-green-200 rounded-md bg-green-50 dark:bg-green-400/20 dark:border-green-500/50" id="successAlert">
                                You have <b>successfully</b> signed in.
                            </div>}
                            {error && <div className="px-4 py-3 mb-3 text-sm text-red-500 border border-red-200 rounded-md bg-red-50 dark:bg-red-400/20 dark:border-red-500/50" id="successAlert">
                                You have <b>failed</b> signed in.
                            </div>}
                            <div className="mb-3">
                                <label htmlFor="email" className="inline-block mb-2 text-base font-medium">UserName/ Email ID</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter username or email"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.email || ""}
                                />
                                {validation.touched.email && validation.errors.email ? (
                                    <div id="email-error" className="mt-1 text-sm text-red-500">{validation.errors.email}</div>
                                ) : null}

                            </div>
                            <div className="mb-3">
                                <label htmlFor="password" className="inline-block mb-2 text-base font-medium">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    placeholder="Enter password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.password || ""}
                                />
                                {validation.touched.password && validation.errors.password ? (
                                    <div id="password-error" className="mt-1 text-sm text-red-500">{validation.errors.password}</div>
                                ) : null}
                            </div>
                            <div>
                                <div className="flex items-center gap-2">
                                    <input id="checkboxDefault1" className="size-4 border rounded-sm appearance-none bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400" type="checkbox" value="" />
                                    <label htmlFor="checkboxDefault1" className="inline-block text-base font-medium align-middle cursor-pointer">Remember me</label>
                                </div>
                                {/* <div id="remember-error" className="hidden mt-1 text-sm text-red-500">Please check the "Remember me" before submitting the form.</div> */}
                            </div>
                            <div className="mt-10">
                                {!isLoading ?
                                    <button type="submit" className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">Sign In</button>
                                    :
                                    <button type="button" disabled className="flex w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" style={{ marginLeft: '35%' }} /> Authenticating...
                                    </button>
                                }
                            </div>

                            <div className="mt-10 text-center">
                                <p className="mb-0 text-slate-500 dark:text-zink-200">Forgot password ? <Link to="/auth-reset-password-basic" className="font-semibold underline transition-all duration-150 ease-linear text-slate-500 dark:text-zink-200 hover:text-custom-500 dark:hover:text-custom-500"> Click here</Link> </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(Login);
