import TableContainer from 'Common/TableContainer';
import { set } from 'date-fns';
import { addProduct, addProductVariant, getBrands, getCategoryList, getPackagings, getProductList, getProductSizes, getSeasons } from 'helpers/backend_helper';
import { Search } from 'lucide-react';
import React, { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { Loader2 } from "lucide-react";


export const BulkUploadProduct = () => {


  const [categories, setCategories] = useState<any[]>([]);
  const [productSizes, setProductSizes] = useState<any[]>([]);
  const [seasons, setSeasons] = useState<any[]>([]);
  const [packagings, setPackagings] = useState<any[]>([]);
  const [excelData, setExcelData] = useState<any>(null);
  const [productData, setProductData] = useState<any>();
  const [brands, setBrands] = useState<any[]>([]);
  const [keys, setKeys] = useState<any[]>([]);
  const [productList, setProductList] = useState([]);
  const [excelProductList, setExcelProductList] = useState<any[]>([]);




  useEffect(() => {
    loadCategories();
    loadBrands();
    loadProductSizes();
    loadSeasons();
    loadPackagings();
    loadProducts();
  }, [])


  async function loadProducts() {
    try {
      if (productList.length === 0) {

        let response = await getProductList();
        if (response.success) {
          console.log('product', response.data);
          setProductList(response.data as never[]);
        }
      }
    } catch (error) {

    } finally {
    }
  }

  const loadBrands = async () => {
    if (brands.length === 0) {
      const response = await getBrands();
      if (response.success) {
        const brandItems = [{ value: '', label: 'Select', discount: 0 }, ...response.data.map((item: any) => ({ value: item.id, label: item.name, discount: item.discount }))];
        setBrands(brandItems);
      }
    }
  };


  const loadCategories = async () => {
    if (categories.length === 0) {
      try {
        const response = await getCategoryList();
        const categoryItems = response.data.map((item: any) => ({ value: item.id, label: item.name, }));
        setCategories([{ value: '', label: 'Select' }, ...categoryItems,]);
      } catch {
        // ignore error
      }
    }
  }

  const loadProductSizes = async () => {
    if (productSizes.length === 0) {
      try {
        const response = await getProductSizes();
        const prodSizes = response.data.map(({ id, name }) => ({ value: id, label: name }));
        setProductSizes(prodSizes);

      } catch { }
    }
  };


  const loadSeasons = async () => {
    if (seasons.length === 0) {
      try {
        const response = await getSeasons();
        const items = [{ value: '', label: 'Select' }, ...response.data.map(({ id, name }) => ({ value: id, label: name }))];
        setSeasons(items);

      } catch { }
    }
  };


  const loadPackagings = async () => {
    if (packagings.length === 0) {
      try {
        const response = await getPackagings();
        const items = response.data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }));
        setPackagings([{ value: '', label: 'Select' }, ...items]);

      } catch { }
    }
  };



  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event: any) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setExcelData(sheetData);
      const keys = sheetData.length > 0 ? Object.keys(sheetData[0] as any) : [];
      setKeys(keys);
    };
    reader.readAsBinaryString(file);
  };


  useEffect(() => {
    if (excelData?.length > 0) {
      handleImport();
    }
  }, [excelData]);

  const handleFileImport = async () => {
    if (excelData) {
      // loop thru the excel data and add new fields
      console.log('categories', categories);

      excelData.forEach(async (item: any) => {

        const brand = brands.find(({ label }) => label?.toUpperCase() === (item['BrandName'] || '').toUpperCase());
        const category = categories.find(({ label }) => label?.toUpperCase() === (item['Category'] || '').toUpperCase());
        const season = seasons.find(({ label }) => label?.toUpperCase() === (item['Season'] || '').toUpperCase());
        const size = productSizes.find(({ label }) => label?.toUpperCase().trim() === (item['SizeGroups'] || '').toUpperCase().trim());

        item.brandId = brand?.value || 0;
        item.categoryId = category?.value || 0;
        item.seasonId = season?.value || 1;
        item.sizeId = size?.value || 0;

        item.AvailableFrom = '08/15/2024';
        item.AvailableTo = '08/15/2025';
        item.productColor = ["#eee"];

        item.packagingId = 1;
        item.productTags = ["Garments Hub", "Summer Cloths"];

        item.attributes = [
          {
            "attribute": {
              "value": "1",
              "label": "Material"
            },
            "attributeValue": {
              "value": "8",
              "label": "Cotton"
            }
          }
        ];
        item.publish = true;
        item.isActive = true;
        console.log('item1', item);

        const unitPrice = item['UnitPrice'] || 0;
        const discountPercent = item['Discount'] || 0;
        const discountValue = Math.round(((unitPrice / 100) * discountPercent) * 100) / 100.00;

        item.Gender = ['MALE', 'BOYS', 'BOY'].includes(item.Gender.toUpperCase()) ? 'Boys' : ['FEMALE', 'GIRLS', 'GIRL'].includes(item.Gender.toUpperCase()) ? 'Girls' : 'All';

        const product = {
          name: item.ProductName + ' ' + item.SKU,
          description: item.Description,
          sku: item.SKU,
          brandId: item.brandId,

          gender: item.Gender,
          categoryId: item.categoryId,

          availableFromDate: item.AvailableFrom,
          availableToDate: item.AvailableTo,

          productColor: [
            '#eee'
          ],
          seasonId: item.seasonId,
          packOf: item.Pack,
          mrp: 0.00,
          packagingId: 1,
          productTags: [
            'Garments Hub',
            'Summer Cloths'
          ],

          attributes: [
            {
              attribute: {
                value: 1,
                label: "Material"
              },
              attributeValue: {
                value: 8,
                label: "Cotton"
              }
            }
          ],
          publish: true,
          isActive: true

        };

        let productVariant = {
          "productId": 0,
          "sizeId": item.sizeId,
          "color": ["#eee"],
          "packOf": item.Pack || '',
          "unitPrice": item.UnitPrice || 1.00,
          "mrp": 0.00,
          "margin": 0.00,
          "discountPercent": discountPercent,
          "discountValue": discountValue,
          "inventory": 1000,
          "isActive": true
        };

        // check if the product already exists in ProductList

        const existingProduct = productList.find((p: any) => p.name === product.name);
        const prodId = existingProduct && existingProduct['id'];

        if (!prodId) {
          try {
            const response = await addProduct(product);
            console.log('Product-response', response);
            const newProductId = response.data?.rows[0]?.id;
            productVariant.productId = newProductId;
            saveProductVariants(productVariant);

          } catch (error: any) {
            console.log('Product-Error-Response', error.response);
            console.log('Error', productVariant, product);
          }
        } else {
          productVariant.productId = prodId;
          saveProductVariants(productVariant);
        }
      });

      console.log('excelData', excelData);
    }
  }

  const handleImport = async () => {
    console.log('handleImport', excelData);
    if (excelData) {
      // loop thru the excel data and add new fields
      console.log('categories', categories);

      let products: any[] = [];

      excelData.forEach(async (item: any) => {
        //const item = excelData[0];

        const brand = brands.find(({ label }) => label?.toUpperCase() === (item['BrandName'] || '').toUpperCase());
        const category = categories.find(({ label }) => label?.toUpperCase() === (item['Category'] || '').toUpperCase());
        const season = seasons.find(({ label }) => label?.toUpperCase() === (item['Season'] || '').toUpperCase());
        const size = productSizes.find(({ label }) => label?.toUpperCase().trim() === (item['SizeGroups'] || '').toUpperCase().trim());

        item.brandId = brand?.value || 0;
        item.categoryId = category?.value || 0;
        item.seasonId = season?.value || 0;
        item.sizeId = size?.value || 0;

        item.AvailableFrom = '08/15/2024';
        item.AvailableTo = '08/15/2030';
        item.productColor = ["#eee"];

        item.packagingId = 1;
        item.productTags = ["Garments Hub", "Summer Cloths"];

        item.attributes = [
          {
            "attribute": {
              "value": "1",
              "label": "Material"
            },
            "attributeValue": {
              "value": "8",
              "label": "Cotton"
            }
          }
        ];
        item.publish = true;
        item.isActive = true;
        console.log('item1', item);

        const unitPrice = item['UnitPrice'] || 0;
        const discountPercent = item['Discount'] || 0;
        const discountValue = Math.round(((unitPrice / 100) * discountPercent) * 100) / 100.00;

        item.Gender = ['MALE', 'BOYS', 'BOY'].includes(item.Gender?.toUpperCase()) ? 'Boys' : ['FEMALE', 'GIRLS', 'GIRL'].includes(item.Gender?.toUpperCase()) ? 'Girls' : 'All';

        const product = {
          name: item.ProductName + ' ' + item.SKU,
          description: item.Description,
          sku: item.SKU,
          brandId: item.brandId,

          gender: item.Gender,
          categoryId: item.categoryId,

          availableFromDate: item.AvailableFrom,
          availableToDate: item.AvailableTo,

          productColor: [
            '#eee'
          ],
          seasonId: item.seasonId,
          packOf: item.Pack,
          mrp: 0.00,
          packagingId: 1,
          productTags: [
            'Garments Hub',
            'Summer Cloths'
          ],

          attributes: [
            {
              attribute: {
                value: 1,
                label: "Material"
              },
              attributeValue: {
                value: 8,
                label: "Cotton"
              }
            }
          ],
          publish: true,
          isActive: true

        };

        let productVariant = {
          "productId": 0,
          "sizeId": item.sizeId,
          "color": ["#eee"],
          "packOf": item.Pack || '',
          "unitPrice": item.UnitPrice || 1.00,
          "mrp": 0.00,
          "margin": 0.00,
          "discountPercent": discountPercent,
          "discountValue": discountValue,
          "inventory": 1000,
          "isActive": true
        };


        // check if the product already exists in ProductList

        const existingProduct = productList.find((p: any) => p.name === product.name);
        const prodId = existingProduct && existingProduct['id'];

        products.push({
          name: item?.ProductName ?? '' + ' ' + item?.SKU ?? '',
          description: item?.Description ?? '',
          sku: item.SKU ?? '',
          brandId: item.brandId,
          brandName: item?.BrandName ?? '',

          gender: item?.Gender ?? '',
          categoryId: item.categoryId,
          category: item?.Category ?? '',

          availableFromDate: item.AvailableFrom,
          availableToDate: item.AvailableTo,

          productColor: [
            '#eee'
          ],
          seasonId: item.seasonId,
          season: item.Season,
          packOf: item.Pack,
          mrp: 0.00,
          packagingId: 1,
          productTags: [
            'Garments Hub',
            'Summer Cloths'
          ],

          attributes: [
            {
              attribute: {
                value: 1,
                label: "Material"
              },
              attributeValue: {
                value: 8,
                label: "Cotton"
              }
            }
          ],

          productId: prodId || 0,
          size: item['SizeGroups'],
          sizeId: item.sizeId || 0,
          color: ["#eee"],
          unitPrice: item.UnitPrice || 1.00,
          margin: 0.00,
          discountPercent: discountPercent,
          discountValue: discountValue,
          inventory: 1000,

          publish: true,
          isActive: true,
          status: 0

        })

        // if (!prodId) {
        //   try {
        //     const response = await addProduct(product);
        //     console.log('Product-response', response);
        //     const newProductId = response.data?.rows[0]?.id;
        //     productVariant.productId = newProductId;
        //     saveProductVariants(productVariant);

        //   } catch (error: any) {
        //     console.log('Product-Error-Response', error.response);
        //     console.log('Error', productVariant, product);
        //   }
        // } else {
        //   productVariant.productId = prodId;
        //   saveProductVariants(productVariant);
        // }
      });

      // setTimeout(() => {
      //   console.table('excelProductList2', excelProductList);
      // }, 2000);

      // console.log('excelProductList3', excelProductList);

      setExcelProductList(products);

      console.table('excelData', excelData);
    }
  }


  async function callApiForRecords() {
    for (const product of excelProductList) {
      product.status = 3; // processing
      setExcelProductList([...excelProductList]);
      await new Promise(resolve => setTimeout(resolve, 3000));
      console.log('productId', product.productId, new Date(), 'start');

      if (!product.productId) {
        try {
          console.log('productId', product.productId, new Date());

          const response = await addProduct(product);
          console.log('Product-response', response);
          const newProductId = response.data?.rows[0]?.id;
          product.productId = newProductId;
          console.log('variant productId', product.productId, new Date());
          saveProductVariants(product);
          //update the status of product in excelProductList
          product.status = 1;
          setExcelProductList([...excelProductList]);
        } catch (error: any) {
          console.log('Product-Error-Response', error.response);
          product.status = 2;
          setExcelProductList([...excelProductList]);
        }
      } else {
        console.log('variant productId', product.productId, new Date());

        try {
          saveProductVariants(product);
          product.status = 1; // completed
          setExcelProductList([...excelProductList]);
        } catch (error: any) {
          console.log('Product-Error-Response', error.response);
          product.status = 2;
          setExcelProductList([...excelProductList]);
        }
      }

      console.log('productId', product.productId, new Date(), 'end');
    }
  }


  const saveProductVariants = async (productVariant: any) => {
    try {
      const response = await addProductVariant(productVariant);
      console.log('ProductVariant-response', response);
    } catch (error: any) {
      console.log('ProductVariant-Error-Response', error.response);
    }
  }

  const runningStatus = (status: number) => {
    switch (status) {
      case 1:
        return 'Success';
      case 0:
        return '';
      case 2:
        return 'Failed';
      case 3:
        return <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" style={{ marginLeft: '35%' }} />;
      default:
        return '';
    }
  }

  const runningStatusClass = (status: number) => {
    switch (status) {
      case 1:
        return 'text-green-500';
      case 0:
        return '';
      case 2:
        return 'text-red-500';
      case 3:
        return 'text-blue-500';
      default:
        return '';
    }
  }

  const borderStyle = {
    border: '1px solid'
  }


  return (
    <div>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />

      <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
        onClick={() => { callApiForRecords(); }}
      >
        {"Import"}
      </button>
      {excelProductList && (


        <table border={1} style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid' }}>
          <thead>
            <tr>
              <th style={borderStyle}>Product ID</th>
              <th style={borderStyle}>Description</th>
              <th style={borderStyle}>SKU</th>
              <th style={borderStyle}>Brand</th>
              <th style={borderStyle}>Gender</th>
              <th style={borderStyle}>Category</th>
              <th style={borderStyle}>Available From</th>
              <th style={borderStyle}>Available To</th>
              <th style={borderStyle}>Season</th>
              <th style={borderStyle}>Size</th>
              <th style={borderStyle}>Pack Of</th>
              <th style={borderStyle}>MRP</th>
              <th style={borderStyle}>Unit Price</th>
              <th style={borderStyle}>Margin</th>
              <th style={borderStyle}>Discount %</th>
              <th style={borderStyle}>Discount Value</th>
              <th style={borderStyle}>Inventory</th>
              <th style={borderStyle}>Status</th>
              {/* <th style={borderStyle}>Publish</th>
              <th style={borderStyle}>Active</th> */}
            </tr>
          </thead>
          <tbody>
            {excelProductList.map((row, index) => (
              <tr key={index}>
                <td style={borderStyle} className={row.productId === 0 || row.productId === "" ? "text-blue-500" : ""}>{row.productId}</td>
                <td style={borderStyle} className={row?.description?.length === 0 || row?.description === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.description}</td>
                <td style={borderStyle} className={row?.sku === null || row?.sku === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.sku}</td>
                <td style={borderStyle} className={row.brandId === 0 || row.brandId === null ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.brandName}</td>
                <td style={borderStyle} className={row.gender === 0 || row.gender === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.gender}</td>
                <td style={borderStyle} className={row.categoryId === 0 || row.categoryId === null ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.category}</td>
                <td style={borderStyle} className={row.availableFromDate?.length === 0 || row.availableFromDate === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.availableFromDate}</td>
                <td style={borderStyle} className={row.availableToDate?.length === 0 || row.availableToDate === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.availableToDate}</td>
                <td style={borderStyle} className={row.seasonId === 0 || row.seasonId === null ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.season}</td>
                <td style={borderStyle} className={row.sizeId === 0 || row.sizeId === null ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.size}</td>
                <td style={borderStyle} className={row.packOf === 0 || row.packOf === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.packOf}</td>
                <td style={borderStyle} >{row.mrp}</td>
                <td style={borderStyle} className={row.unitPrice === 0 || row.unitPrice === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.unitPrice}</td>
                <td style={borderStyle} >{row.margin}</td>
                <td style={borderStyle} >{row.discountPercent}</td>
                <td style={borderStyle} >{row.discountValue}</td>
                <td style={borderStyle} className={row.inventory === 0 || row.inventory === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.inventory}</td>
                <td style={borderStyle} className={runningStatusClass(row.status)}>
                  {runningStatus(row.status)}

                </td>
                {/* <td style={borderStyle} className={row.publish === 0 || row.publish === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.publish}</td>
                <td style={borderStyle} className={row.isActive === 0 || row.isActive === "" ? "bg-red-500 border-red-500 text-white-500" : ""}>{row.isActive}</td> */}
              </tr>
            ))}
          </tbody>
        </table>

      )}
    </div>
  );
}
