// dashboard
// import Analytics from "pages/Dashboards/Analytics";
import Ecommerce from "pages/Dashboards/Ecommerce";

// Ecommerce
import ListView from "pages/Ecommerce/Products/ProductList";
import AddProduct from "pages/Ecommerce/Products/AddProduct";
import ShoppingCart from "pages/Ecommerce/ShoppingCart";
import Checkout from "pages/Ecommerce/Checkout";
import Orders from "pages/Ecommerce/Order/Orders";
import OrderDetail from "pages/Ecommerce/Order/OrderDetail";
import Sellers from "pages/Ecommerce/Sellers";


// navbar
import NavBars from "pages/Components/Navigation/Navbars";
import NavTabs from "pages/Components/Navigation/Tabs";
import NavigationBreadcrumb from "pages/Components/Navigation/NavigationBreadcrumb";
import Pagination from "pages/Components/Navigation/Pagination";


// auth
import Basic from "pages/AuthenticationInner/Login/Basic";
import LoginCover from "pages/AuthenticationInner/Login/LoginCover";
import LoginBoxed from "pages/AuthenticationInner/Login/LoginBoxed";
import LoginModern from "pages/AuthenticationInner/Login/LoginModern";

//Register
import BasicRegister from "pages/AuthenticationInner/Register/Basic";
import RegisterCover from "pages/AuthenticationInner/Register/RegisterCover";
import RegisterBoxed from "pages/AuthenticationInner/Register/RegisterBoxed";
import RegisterModern from "pages/AuthenticationInner/Register/RegisterModern";

// Category
import CategoryList from "pages/Ecommerce/Categories/CategoryList";


// EmailVerify
import BasicEmailVerify from "pages/AuthenticationInner/VerifyEmail/Basic";
import EmailCover from "pages/AuthenticationInner/VerifyEmail/EmailCover";
import EmailModern from "pages/AuthenticationInner/VerifyEmail/EmailModern";

// TwoSteps
import BasicTwoSteps from "pages/AuthenticationInner/TwoSteps/Basic";
import TwoStepsCover from "pages/AuthenticationInner/TwoSteps/TwoStepsCover";
import TwoStepsBoxed from "pages/AuthenticationInner/TwoSteps/TwoStepsBoxed";
import TwoStepsModern from "pages/AuthenticationInner/TwoSteps/TwoStepsModern";

// Logout
import BasicLogout from "pages/AuthenticationInner/Logout/Basic";
import LogoutCover from "pages/AuthenticationInner/Logout/LogoutCover";
import LogoutBoxed from "pages/AuthenticationInner/Logout/LogoutBoxed";
import LogoutModern from "pages/AuthenticationInner/Logout/LogoutModern";

// Reset Password
import BasicResetPassword from "pages/AuthenticationInner/ResetPassword/Basic";
import ResetPasswordCover from "pages/AuthenticationInner/ResetPassword/ResetPasswordCover";
import ResetPasswordBoxed from "pages/AuthenticationInner/ResetPassword/ResetPasswordBoxed";
import ResetPasswordModern from "pages/AuthenticationInner/ResetPassword/ResetPasswordModern";

// Create Password
import BasicCreatePassword from "pages/AuthenticationInner/CreatePassword/Basic";
import CreatePasswordModern from "pages/AuthenticationInner/CreatePassword/CreatePasswordModern";
import CreatePasswordCover from "pages/AuthenticationInner/CreatePassword/CreatePasswordCover";
import CreatePasswordBoxed from "pages/AuthenticationInner/CreatePassword/CreatePasswordBoxed";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/LogOut";
import Pages404 from "pages/AuthenticationInner/Pages404";
import Register from "pages/Authentication/Register";
import UserProfile from "pages/Authentication/UserProfile";
import Account from "pages/Pages/Account";
import ComingSoon from "pages/AuthenticationInner/ComingSoon";
// import Offline from "pages/AuthenticationInner/Offline";
import Maintenance from "pages/AuthenticationInner/Maintenance";
import Settings from "pages/Pages/Settings";
// import Pricing from "pages/Pages/Pricing";
import Faqs from "pages/Pages/Faqs";
import ContactUs from "pages/Pages/ContactUs";
import FAQList from "pages/Ecommerce/FAQ/FAQList";
import CMSList from "pages/Ecommerce/CMS/CMSList";
import AnnouncementList from "pages/Ecommerce/Announcement/AnnouncementList";
import BannerList from "pages/Ecommerce/Banners/BannerList";
import CouponList from "pages/Ecommerce/Coupon/CouponList";
import BrandList from "pages/Ecommerce/Brands/BrandList";
import { ProductColorList } from "pages/Ecommerce/Product-Color/ProductColorList";
import AttributeValueList from "pages/Ecommerce/AttributeValue/AttributeValueList";
import ProductSizeList from "pages/Ecommerce/ProductSize/ProductSizeList";
import DistrictList from "pages/Ecommerce/District/DistrictList";
import CityList from "pages/Ecommerce/City/CityList";
import AttributeList from "pages/Ecommerce/Attribute/AttributeList";
import Carts from "pages/Ecommerce/Cart/Carts";
import CartDetail from "pages/Ecommerce/Cart/CartDetail";
import Wishlist from "pages/Ecommerce/Wishlist/Wishlist";
import WishlistDetail from "pages/Ecommerce/Wishlist/WishlistDetail";

import UserList from "pages/Ecommerce/User/UserList";
import UserRoles from "pages/Ecommerce/UserRoles/UserRoles";
import Roles from "pages/Ecommerce/Roles/Roles";
import Resources from "pages/Ecommerce/Resources/Resouces";
import PermissionList from "pages/Ecommerce/Permissions/PermissionList";
import { BulkUploadProduct } from "pages/Ecommerce/Products/BulkUploadProduct";
import UnverifiedBusiness from "pages/Ecommerce/VerifyDocuments/UnverifiedBusiness";
import { UnverifiedBusinessDetails } from "pages/Ecommerce/VerifyDocuments/UnverifiedBusinessDetails";
import UserProductHistory from "pages/Ecommerce/UserProductHistory/UserProductHistory";
import UserProductHistoryDetail from "pages/Ecommerce/UserProductHistory/UserProductHistoryDetail";
import FrequentyAskedQuestions from "assets/static/FrequentyAskedQuestions";


interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  { path: "/", component: Ecommerce },
  { path: "/dashboard", component: Ecommerce },

  // navigation
  { path: "/navigation-navbars", component: NavBars },
  { path: "/navigation-tabs", component: NavTabs },
  { path: "/navigation-breadcrumb", component: NavigationBreadcrumb },
  { path: "/navigation-pagination", component: Pagination },

  // Products
  { path: "/product-list", component: ListView },
  { path: "/product-create", component: AddProduct },
  { path: "/product-create/:prodId", component: AddProduct },
  { path: "/bulk-upload-product", component: BulkUploadProduct },

  { path: "/cart", component: ShoppingCart },
  { path: "/checkout", component: Checkout },
  { path: "/orders", component: Orders },
  { path: "/order-detail/:orderId", component: OrderDetail },

  { path: "/carts", component: Carts },
  { path: "/cart-detail/:userId/:businessId", component: CartDetail },

  { path: "/wishlist", component: Wishlist },
  { path: "/wishlist-detail/:userId/:businessId", component: WishlistDetail },

  { path: "/sellers", component: Sellers },

  { path: "/category-list", component: CategoryList },

  {path:"/brands-list", component: BrandList},
  // {path:"/brands-create",component: AddBrand},
  // {path:"/brands-create/:id",component: AddBrand},


  {path:"/faq-list", component: FAQList},
  
  {path:"/cms-list", component: CMSList},

  {path:"/userlist", component: UserList},
  
  {path:"/user-roles", component: UserRoles},

  {path:"/roles", component: Roles},

  {path:"/resources", component: Resources},

  {path:"/permissions", component: PermissionList},


  //annoucement
  {path:"/announcement-list", component: AnnouncementList},

//banners
  {path:"/banner-list", component: BannerList},

//coupon
  {path:"/coupon-list", component: CouponList},
  {path:"/product-color-list", component: ProductColorList},
  { path: "/attribute-value-list", component: AttributeValueList},
  { path: "/attribute-list", component: AttributeList},
  { path: "/product-size-list", component: ProductSizeList},
  { path: "/district-list", component: DistrictList},

  //CityList
  {path:"/city-list", component: CityList},
  
  // pages
  { path: "/pages-account", component: Account },
  { path: "/pages-account-settings", component: Settings },
  { path: "/pages-faqs", component: Faqs },
  { path: "/pages-contact-us", component: ContactUs },

  // profile
  { path: "/user-profile", component: UserProfile },

  { path: "/unverified-business", component: UnverifiedBusiness },
  { path: "/unverified-business-detail/:businessId", component: UnverifiedBusinessDetails },

  // product history

  { path: "/user-product-history", component: UserProductHistory },
  { path: "/user-product-history/:userId", component: UserProductHistoryDetail },
];

const publicRoutes = [

  // auth
  { path: "/auth-login-basic", component: Basic },
  { path: "/auth-login-cover", component: LoginCover },
  { path: "/auth-login-boxed", component: LoginBoxed },
  { path: "/auth-login-modern", component: LoginModern },

  // Register
  { path: "/auth-register-basic", component: BasicRegister },
  { path: "/auth-register-cover", component: RegisterCover },
  { path: "/auth-register-boxed", component: RegisterBoxed },
  { path: "/auth-register-modern", component: RegisterModern },

  // Verify Email
  { path: "/auth-verify-email-basic", component: BasicEmailVerify },
  { path: "/auth-verify-email-cover", component: EmailCover },
  { path: "/auth-verify-email-modern", component: EmailModern },

  // two steps
  { path: "/auth-two-steps-basic", component: BasicTwoSteps },
  { path: "/auth-two-steps-cover", component: TwoStepsCover },
  { path: "/auth-two-steps-boxed", component: TwoStepsBoxed },
  { path: "/auth-two-steps-modern", component: TwoStepsModern },

  // logout
  { path: "/auth-logout-basic", component: BasicLogout },
  { path: "/auth-logout-cover", component: LogoutCover },
  { path: "/auth-logout-boxed", component: LogoutBoxed },
  { path: "/auth-logout-modern", component: LogoutModern },

  //Reset Password
  { path: "/auth-reset-password-basic", component: BasicResetPassword },
  { path: "/auth-reset-password-cover", component: ResetPasswordCover },
  { path: "/auth-reset-password-boxed", component: ResetPasswordBoxed },
  { path: "/auth-reset-password-modern", component: ResetPasswordModern },

  //Create Password
  { path: "/auth-create-password-basic", component: BasicCreatePassword },
  { path: "/auth-create-password-cover", component: CreatePasswordCover },
  { path: "/auth-create-password-boxed", component: CreatePasswordBoxed },
  { path: "/auth-create-password-modern", component: CreatePasswordModern },

  // coming soon
  { path: "/pages-coming-soon", component: ComingSoon },

  // Error
  //{ path: "/pages-offline", component: Offline },
  { path: "/pages-404", component: Pages404 },

  // Maintenance
  { path: "/pages-maintenance", component: Maintenance },


  // authentication
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },

  { path: "/FrequentyAskedQuestions", component: FrequentyAskedQuestions },

// UnverifiedDocs

]

export { authProtectedRoutes, publicRoutes };
