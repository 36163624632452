import React, { useContext } from 'react';
import { PermissionType } from "types/PermissionType";
import PermissionContext from "./PermissionContext";
import { Common } from 'helpers/common';
import { AppContext } from 'context/AppContext';

type Props = {
    children: React.ReactNode // Add the 'children' property to the 'Props' type
}

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<Props> = ({ children }) => {

    const { permissions } = useContext(AppContext);

    // Creates a method that returns whether the requested permission is available in the list of permissions
    // passed as parameter
    //const isAllowedTo = (page: PermissionType,permission: PermissionType) => permissions.some(p=>p.resource===page);

    const isAllowedTo = (page: PermissionType, permission: PermissionType) => {

        const common = new Common();
        const role = localStorage.getItem("IDKS@ROLE") || "";
        const isAdmin = !role ? false : common.decrypt(role) === "admin";

        // if (page == 'products') {
        //     console.log('isAllowedTo', isAdmin)
        //     console.log('permission', permission)
        // }

        return isAdmin || (permissions.some(p => p.resource === page && ((permission === "") || p.actions?.search(permission) !== -1)));
    }
    // This component will render its children wrapped around a PermissionContext's provider whose
    // value is set to the method defined above
    return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;