import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";

// Images
import avatar4 from "assets/images/users/avatar-4.png";

// icons
import { CreditCard } from 'lucide-react';
import { Link, useParams } from "react-router-dom";
import { getUserProductHistoryById, getWishlistById } from "helpers/backend_helper";
import { format } from 'date-fns';// Icons
import { BusinessDetails, WishlistDetailResponse, WishlistItem, WishlistSummary, WishlistUser } from "types/WishlistType";


const UserProductHistoryDetail = () => {
    const { userId } = useParams();
    const [userProductDetail, setUserProductDetail] = useState<any>({} as any);
    const [dateAdded, setDateAdded] = useState<any>();

    // Get Data
    useEffect(() => {
        onGetWishlistDetail();
    }, []);

    const onGetWishlistDetail = async () => {
        try {
            const response = await getUserProductHistoryById(userId);
            console.log("Wishlist Data", response.data[0]);
            // const userProducts: WishlistDetailResponse = {
            //     wishlistitems: response.data[0].wishlistitems,
            //     wishlistUser: {} as WishlistUser,
            //     businessDetails:  {} as BusinessDetails,
            //     wishlistSummary: {} as WishlistSummary
            //     // Initialize other properties of WishlistDetailResponse if needed
            // };

            setUserProductDetail(response.data[0]);
            setDateAdded(response.data[0].wishlistitems[0].dateAdded);
        } catch (err) { }
    };

    const netPrice = (item: WishlistItem) => {
        return (item.unitPrice - item.discount);
    }

    const setPrice = (item: WishlistItem) => {
        return item.packOf * (item.unitPrice - item.discount);
    }

    return (
        <React.Fragment>
            <BreadCrumb title='Search History' pageTitle='' />
            <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
                <div className="2xl:col-span-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="flex items-center justify-center size-12 bg-orange-100 rounded-md dark:bg-orange-500/20 ltr:float-right rtl:float-left">
                                <CreditCard className="text-orange-500 fill-orange-200 dark:fill-orange-500/30" />
                            </div>
                            <h6 className="mb-4 text-15">Billing Details</h6>

                            <h6 className="mb-1">{userProductDetail?.businessDetails?.businessName}</h6>
                            <p className="mb-1 text-slate-500 dark:text-zink-200">{userProductDetail?.businessDetails?.addressLine1}, {userProductDetail?.businessDetails?.addressLine2}</p>
                            <p className="text-slate-500 dark:text-zink-200">{userProductDetail?.businessDetails?.city}, {userProductDetail?.businessDetails?.district}, {userProductDetail?.businessDetails?.state}, {userProductDetail?.businessDetails?.pinCode}</p>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="size-12 bg-yellow-100 rounded-md ltr:float-right rtl:float-left dark:bg-yellow-500/20">
                                <img src={avatar4} alt="" className="h-12 rounded-md" />
                            </div>
                            <h6 className="mb-4 text-15">Customer Info</h6>

                            <h6 className="mb-1">{userProductDetail?.user?.userName}</h6>
                            <p className="mb-1 text-slate-500 dark:text-zink-200">{userProductDetail?.user?.email}</p>
                            <p className="text-slate-500 dark:text-zink-200">+91 {userProductDetail?.user?.phone}</p>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
                <div className="2xl:col-span-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="flex items-center gap-3 mb-4">
                                <h6 className="text-15 grow">Searched Items</h6>
                            </div>
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <tbody>

                                        {userProductDetail?.items?.map((item: WishlistItem, index: number) => (
                                            <tr key={`Wishlistitems-${index}`} >
                                                <td className="px-3.5 py-4 bcart-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500">
                                                    <div className="flex items-center gap-3">
                                                        <div className="flex items-center justify-center size-18 rounded-md bg-slate-200 shrink-0">
                                                            <img src={item.imageUrl} alt="" className="h-24" />
                                                        </div>
                                                        <div className="grow">
                                                            <h6 className="mb-1 text-15"><Link to="/product-overview" className="transition-all duration-300 ease-linear hover:text-custom-500">
                                                                {item?.brandName},  {item?.productName}</Link></h6>

                                                            <div className="flex">
                                                                <div
                                                                    key={`$sizes-${101}`}
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        marginRight: 10,
                                                                    }}
                                                                    className="mr-2 mb-2"
                                                                >
                                                                    Size : {item?.sizes?.join(',')}
                                                                </div>

                                                                <div
                                                                    key={`$color-${100}`}
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        marginRight: 10,
                                                                    }}
                                                                    className="mr-2 mb-2"
                                                                >
                                                                    Colors:
                                                                </div>
                                                                {item?.color?.map((itemColor: any, idx: number, arr: any[]) => {
                                                                    const lastElement = idx === arr.length - 1;
                                                                    const marginRight = lastElement ? 0 : 4;
                                                                    return (
                                                                        <div
                                                                            key={`$color-${idx}`}
                                                                            style={{
                                                                                width: 16,
                                                                                height: 16,
                                                                                backgroundColor: itemColor,
                                                                                borderRadius: 10,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                marginRight: marginRight,
                                                                            }}
                                                                            className="mr-2 mb-2"
                                                                        >
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <p className="text-slate-500 dark:text-zink-200">Set of : {item.packOf} Pcs</p>
                                                            {/* <p className="text-slate-500 dark:text-zink-200">Quantity : {item.quantity}</p> */}

                                                            <p className="text-slate-500 dark:text-zink-200">Unit Price : ₹{item.unitPrice} / Pc, Discount : ₹{item.discount} , Net Price : ₹{netPrice(item)} / Pc</p>
                                                            {/* <p className="text-slate-500 dark:text-zink-200">Discount : ₹{item.discountValue} / Pc</p>
                                                            <p className="text-slate-500 dark:text-zink-200">Net Price : ₹{netPrice(item)} / Pc</p> */}

                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-3.5 py-4 border-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500 ltr:text-right rtl:text-left">
                                                    ₹{setPrice(item)}
                                                </td>
                                            </tr>
                                        ))}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </React.Fragment>
    );
};

export default UserProductHistoryDetail;