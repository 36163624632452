import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import { Loader } from "../../../Common/Loader";
// Icons
import { Pencil, Search, Trash2, Plus, Link2, Link2Icon } from "lucide-react";
import Modal from "Common/Components/Modal";
import DeleteModal from "Common/DeleteModal";

import { getUnverifiedBusiness, } from "../../../helpers/backend_helper";
import { ToastContainer } from "react-toastify";
import { Common } from "helpers/common";
import { UpdateDocStatus } from "./UpdateDocStatus";
import { PendingBusinessType } from "types/PendingBusinessType";

function UnverifiedBusiness() {
  const [unverifiedBusiness, setUnverifiedBusiness] = useState<PendingBusinessType[]>([]);
  const [eventData, setEventData] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const uiHelper = new Common();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadUnverifiedBusiness();
  }, []);

  async function loadUnverifiedBusiness() {
    try {
      if (unverifiedBusiness.length === 0) {
        let response = await getUnverifiedBusiness();
        if (response.success) {
          console.log("UnverifiedBusiness", response.data);
          setUnverifiedBusiness(response.data as never[]);
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  //Delete Modal
  const deleteToggle = () => setDeleteModal(!deleteModal);

  //Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };

  const handleDelete = async () => {
    if (eventData) {
      try {
        //   await deleteUserList(eventData.id);

        //    setDeleteModal(false);
        //   uiHelper.showSuccessMessage("User has been deleted successfully.");
        //   // remove the item and then update list
        //   setUser((user) =>
        //     user.filter((data: any, idx) => data.id != eventData.id)
        //   );

      }
      catch (error: any) {
        uiHelper.showErrorMessage("An error occured while deleting user. Please try again later.");
      }
    }
  };
  // const handleEditDataClick = (ele: any) => {
  //   setUserData({ ...ele });
  //   setIsEdit(true);
  //   setShow(true);
  // };

  const handleAddUsers = () => {
    setUserData({});
    toggle();
  };
  const onSave = (result: any) => {
    if (isEdit) {
      //   setUser((prevData) =>
      //     prevData.map((data: any, idx) =>
      //       data.id === userData.id
      //         ? ({ ...result } as never)
      //         : ({ ...data } as never)
      //     )
      //   );
      //   setIsEdit(false);
    } else {
      //setUser((prevData) => [{ ...result } as never, ...prevData]);
    }
    toggle();
  };
  const toggle = useCallback(() => {
    setShow(!show);
    if (show) {
      setUserData(null);
    }
  }, [show]);


  const handleEditDataClick = (ele: any) => {
    console.log(ele);
    navigate('/unverified-business-detail/' + ele.businessId);
  };

  const Status = ({ item }: any) => {
    switch (item) {
      case true:
        return (
          <span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Active
          </span>
        );
      case false:
        return (
          <span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">
            InActive
          </span>
        );
    }
  };
  const columns = useMemo(
    () => [
      {
        header: "Customer Name",
        accessorKey: "contactPerson",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Business Name",
        accessorKey: "businessName",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Phone Number",
        accessorKey: "contactPhone",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Email",
        accessorKey: "contactEmail",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Document Name",
        accessorKey: "documentName",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Document #",
        accessorKey: "documentNumber",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex justify-start gap-2">

            {/* <a href={cell.row.original.documentUrl} target="_blank">
  <img src={cell.row.original.documentUrl} width="200" height="300"/>
</a> */}
{/* 
            <Link
              to={cell.row.original.documentUrl}
              target="_blank"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-green-500 bg-green-100 hover:text-white hover:bg-green-500 dark:bg-green-500/20 dark:hover:bg-green-500"
            >
              <Link2Icon className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
            </Link> */}

            <button
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
              onClick={() => {
                const data = cell.row.original;
                handleEditDataClick(data);
              }}>
              <Pencil className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
            </button>

            {/* <Link
              data-modal-target="addUserModal"
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
              onClick={() => {
                const data = cell.row.original;
                handleEditDataClick(data);
              }}
            >
              <Pencil className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
            </Link> */}

          </div>
        ),
      },
    ],
    []
  );
  // const options = [
  //   { value: "Select Status", label: "Select Status" },
  //   { value: true, label: "Active" },
  //   { value: "false", label: "inActive" },
  // ];

  // const handleChange = (selectedOption: any) => {
  //   if (
  //     selectedOption.value === "Select Status" ||
  //     selectedOption.value === "Hidden"
  //   ) {
  //     setUser(user);
  //   } else {
  //     const filteredUsers = user.filter(
  //       (data: any) => data.isActive === selectedOption.value
  //     );
  //     setUser(filteredUsers);
  //   }
  // };
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      {/* <div className="card" id="userListTable">
        <div className="card-body"> */}
      <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12" style={{ marginTop: 15 }}>
        <div className="xl:col-span-12">
          <div className="card" id="userListTable">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Unverified Docs</h6>
                <div className="shrink-0">

                </div>
              </div>
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <div style={{ marginBottom: 20 }} >
                {unverifiedBusiness && unverifiedBusiness?.length > 0 ? (
                  <TableContainer
                    isPagination={true}
                    columns={columns || []}
                    data={unverifiedBusiness || []}
                    customPageSize={10}
                    divclassName="overflow-x-auto"
                    tableclassName="w-full whitespace-nowrap"
                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                    thclassName="px-3.5 py-1.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                    tdclassName="px-3.5 py-1.5 border-y border-slate-200 dark:border-zink-500"
                    PaginationClassName="flex flex-col items-center gap-4 px-4 mt-3 md:flex-row"
                    SearchPlaceholder='Search...'
                    isGlobalFilter={false}
                    isSelect={true}
                  />
                ) : (
                  <div className="noresult">
                    <div className="py-6 text-center">
                      <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                      <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}

      {/* User Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[70rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        {userData ? (
          <UpdateDocStatus onSave={onSave} onHide={toggle} data={userData} />
        ) : (
          <></>
        )}
      </Modal>
    </React.Fragment>
  );
}

export default UnverifiedBusiness;
