import React from 'react';
import './assets/scss/themes.scss';
import RouteIndex from 'Routes/Index';
import { AppContextProvider } from 'context/AppContext';

// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
//fakeBackend();




function App() {
  return (
    <AppContextProvider>
    <RouteIndex />
    </AppContextProvider>
  );
}

export default App;