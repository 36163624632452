import { getFAQList } from 'helpers/backend_helper';
import React, { useEffect, useState } from 'react';

type CollapsiblePanelProps = {
    title: string;
    children: React.ReactNode;
};

const CollapsiblePanel = ({ title, children }: CollapsiblePanelProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={styles.panelContainer}>
            <div  onClick={togglePanel} style={styles.header}>
                <h2 style={styles.title}>{title}</h2>
                <span style={styles.toggleIcon}>{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && <div style={styles.content}>{children}</div>}
        </div>
    );
};

const styles = {
    panelContainer: {
        border: '1px solid #ccc',
        borderRadius: '10px', // Rounded corners
        overflow: 'hidden',
        marginBottom: '10px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 15px',
        backgroundColor: '#f0f0f0',
        cursor: 'pointer',
        borderRadius: '10px 10px 0 0', // Top rounded corners
    },
    title: {
        margin: 0,
        fontSize: '16px',
    },
    toggleIcon: {
        fontSize: '24px',
    },
    content: {
        padding: '15px',
        backgroundColor: '#fff',
        borderTop: '1px solid #ccc',
        borderRadius: '0 0 10px 10px', // Bottom rounded corners
        fontSize: '14px',
    },
};

const FrequentyAskedQuestions = () => {
    const [faqList, setFaqList] = useState(faqs);

    return (
        <React.Fragment>
            <div style={{ padding: '20px' }}>
                {faqList.map((faq) => (
                    <CollapsiblePanel key={faq.id} title={faq.title}>
                        <p>{faq.description}</p>
                    </CollapsiblePanel>
                ))}
            </div>
        </React.Fragment>
    );
};

export default FrequentyAskedQuestions;

export const faqs=
[

    {
        "id": 2,
        "title": "What should I do if I forget my password?",
        "description": "Select 'Forgot Password' on the login page, enter your email, and follow the instructions sent to reset your password."
    },
    {
        "id": 3,
        "title": "Can I make purchases without creating an account?",
        "description": "In most cases, guest checkout is available. However, creating an account offers a smoother checkout experience, order tracking, and access to exclusive deals."
    },
    {
        "id": 4,
        "title": "How do I search for products?",
        "description": "Use the search bar at the top of the screen to type in keywords, product names, or categories. You can also browse by category using the navigation menu."
    },
    {
        "id": 5,
        "title": "Can I save items for later?",
        "description": "Yes, you can add items to your wishlist by tapping the heart icon on the product page. Access your wishlist from the profile or menu section."
    },
    {
        "id": 6,
        "title": "How do I know if a product is in stock?",
        "description": "The availability status is displayed on each product page. If an item is out of stock, it will be marked accordingly."
    },
    {
        "id": 7,
        "title": "What payment methods do you accept?",
        "description": "We accept credit/debit cards, PayPal, Apple Pay, Google Pay, and certain prepaid gift cards. Other options like Cash on Delivery (COD) may be available based on your location."
    },
    {
        "id": 8,
        "title": "Can I apply a discount code to my order?",
        "description": "Yes, during checkout, there is an option to enter a discount or promo code. Ensure the code is valid and applicable to your order."
    },
    {
        "id": 9,
        "title": "How do I cancel or modify an order?",
        "description": "You can cancel or modify an order before it’s shipped by going to the 'My Orders' section. Once shipped, changes are not possible."
    },
    {
        "id": 10,
        "title": "How much does shipping cost?",
        "description": "Shipping costs vary based on location, order amount, and shipping method chosen. During checkout, you’ll see the exact shipping fees before completing your purchase."
    },
    {
        "id": 11,
        "title": "How long does it take to receive my order?",
        "description": "Delivery times vary by location and shipping method, ranging from 2 to 7 business days. Estimated delivery dates are provided at checkout."
    },
    {
        "id": 12,
        "title": "How can I track my order?",
        "description": "After your order is shipped, you’ll receive a tracking number via email. Use this number on the courier’s website or in the 'My Orders' section to track your package."
    },
    {
        "id": 13,
        "title": "What is your return policy?",
        "description": "You can return items within 30 days of purchase if they’re unused and in original packaging. Some items, like final sale items, may not be eligible for returns."
    },
    {
        "id": 14,
        "title": "How do I request a return?",
        "description": "Go to the 'My Orders' section, select the item you wish to return, and follow the return instructions. You’ll receive an email with further steps."
    },
    {
        "id": 15,
        "title": "When will I receive my refund?",
        "description": "Refunds are processed within 5-10 business days after receiving and inspecting the returned item. Payment method refunds may take additional processing time with your bank."
    },
    {
        "id": 16,
        "title": "How do I use my reward points?",
        "description": "During checkout, select the option to apply your reward points to your order. Points can be used for discounts on eligible items."
    },
    {
        "id": 17,
        "title": "Can I combine multiple discount codes?",
        "description": "Generally, only one discount code can be applied per order unless specified otherwise in the promotion's terms."
    },
    {
        "id": 18,
        "title": "Why am I having trouble with the app?",
        "description": "Ensure you’re using the latest version of the app. Clear your app cache, check your internet connection, or restart your device if issues persist."
    },
    {
        "id": 19,
        "title": "How do I contact customer support?",
        "description": "Tap on 'Contact Us' in the app menu to reach our support team via chat, email, or phone. Customer support is available 24/7."
    },
    {
        "id": 20,
        "title": "Is my payment information secure?",
        "description": "Yes, we use SSL encryption to protect your payment information, and we do not store full card details on our servers."
    },
    {
        "id": 21,
        "title": "How do I update my personal information?",
        "description": "Go to 'Account Settings' in the profile section to update your address, phone number, or email preferences."
    }
]

