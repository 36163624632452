import exp from "constants";
import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged in user data from local session

// Gets the logged in user data from local session
export const getLoggedUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedUser() !== null;
};

// Register Method
export const postRegister = (data: any) => api.create(url.POST_REGISTER, data);

// Login Method
export const postLogin = (data: any) => api.create(url.POST_LOGIN, data);

// Refresh Token
export const refreshToken = (data: string) => api.create(url.POST_REFRESH_TOKEN, data);

// postForgetPwd
export const postForgetPwd = (data: any) => api.create(url.POST_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);
// export const postProfile = (data: any) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data)
    .catch((err: any) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};
// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

// Chat
export const getChat = (roomId: any) => api.get(`${url.GET_CHAT}/${roomId}`, { params: { roomId } });
export const addChat = (data: any) => api.create(url.ADD_CHAT, data);
export const deleteChat = (data: any) => api.delete(url.DELETE_CHAT, { headers: { data } });
export const bookmarkChat = (data: any) => api.delete(url.BOOKMARK_CHAT, { headers: { data } });


//Categories
export const getCategoryList = () => api.get(url.GET_CATEGORY_LIST, null);
export const addCategoryList = (data: any) => api.create(url.ADD_CATEGORY_LIST, data);
export const updateCategoryList = (data: any) => api.put(url.UPDATE_CATEGORY_LIST+ data.id, data);
export const deleteCategoryList = (data: any) => api.delete(url.DELETE_CATEGORY_LIST+data, data);
export const uploadCategoryImage = (data: any) => api.createWithFormData(url.POST_CATEGORY_IMAGE, data);


// City
export const getCity = () => api.get(url.GET_CITIES, null);
export const getStates = () => api.get(url.GET_STATES, null);
export const getDistricts = (param:string) => api.get(url.GET_DISTRICTS+param, null);
export const getCities = (param:string) => api.get(url.GET_CITIES+param, null);


// Brands
export const getBrands = () => api.get(url.GET_BRANDS, null);
export const getBrandByID = (id: any) => api.get(url.GET_BRANDS, id);
export const addBrand = (data: any) => api.create(url.ADD_BRANDS, data);
export const updateBrand = (data: any) => api.put(url.UPDATE_BRANDS + data.id, data);
export const deleteBrand = (data: any) => api.delete(url.DELETE_BRANDS+ data,data );
export const uploadBrandImage = (data: any) => api.createWithFormData(url.POST_BRAND_IMAGE, data);


//FAQS
export const getFAQList = () => api.get(url.GET_FAQ_LIST, null);
export const addFAQList = (data: any) => api.create(url.ADD_FAQ_LIST, data);
export const updateFAQList = (data: any) => api.put(url.UPDATE_FAQ_LIST+ data.id, data);
export const deleteFAQList = (data: any) => api.delete(url.DELETE_FAQ_LIST+data, data);

//CMS
export const getCMSList = () => api.get(url.GET_CMS_LIST, null);
export const addCMSList = (data: any) => api.create(url.ADD_CMS_LIST, data);
export const updateCMSList = (data: any) => api.put(url.UPDATE_CMS_LIST+ data.id, data);
export const deleteCMSList = (data: any) => api.delete(url.DELETE_CMS_LIST+data, data);


//USERS

export const getUsers = () => api.get(url.GET_USERS , null);
export const addUsers = (data: any) => api.create(url.ADD_USERS , data);
export const updateUsers = (data: any) => api.put(url.UPDATE_USERS + data.id, data);
export const deleteUsers = (data: any) => api.delete(url.DELETE_USERS +data, data);
export const getUsersById = () => api.get(url.GET_USERS_BY_ID , null);

//ROLES
export const getRoles = () => api.get(url.GET_ROLES , null);
export const addRoles = (data: any) => api.create(url.ADD_ROLES , data);
export const updateRoles =  (data: any) => api.put(url.UPDATE_ROLES + data.id, data);
export const deleteRoles = (data: any) => api.delete(url.DELETE_ROLES +data, data);
export const getRolesById =  () => api.get(url.GET_ROLES_BY_ID , null);
export const getUserRoles = () => api.get(url.GET_USER_ROLES , null);
export const getSalesRepresentatives = () => api.get(url.GET_SALES_REPRESENTATIVE , null);



//RESOURCES
export const getResources = () => api.get(url.GET_RESOURCES , null);
export const addResources = (data: any) => api.create(url.ADD_RESOURCES , data);
export const updateResources =  (data: any) => api.put(url.UPDATE_RESOURCES + data.id, data);
export const deleteResources = (data: any) => api.delete(url.DELETE_RESOURCES +data, data);

//PERMISSIONS
export const getPermissions = () => api.get(url.GET_PERMISSIONS , null);
export const addPermissions = (data: any) => api.create(url.ADD_PERMISSIONS , data);
export const updatePermissions =  (data: any) => api.put(url.UPDATE_PERMISSIONS + data.id, data);
export const deletePermissions = (data: any) => api.delete(url.DELETE_PERMISSIONS +data, data);

//Annoucement
export const getAnnouncementList = () => api.get(url.GET_ANNOUNCEMENT_LIST, null);
export const addAnnouncementList = (data: any) => api.create(url.ADD_ANNOUNCEMENT_LIST, data);
export const updateAnnouncementList = (data: any) => api.put(url.UPDATE_ANNOUNCEMENT_LIST+ data.id, data);
export const deleteAnnouncementList = (data: any) => api.delete(url.DELETE_ANNOUNCEMENT_LIST+data, data);


//Banner
export const getBannerList = () => api.get(url.GET_BANNER_LIST, null);
export const addBannerList = (data: any) => api.create(url.ADD_BANNER_LIST, data);
export const updateBannerList = (data: any) => api.put(url.UPDATE_BANNER_LIST+ data.id, data);
export const deleteBannerList = (data: any) => api.delete(url.DELETE_BANNER_LIST+data, data);
export const uploadBannerImage = (data: any) => api.createWithFormData(url.POST_BANNER_IMAGE, data);

//Coupon
export const getCouponList = () => api.get(url.GET_COUPON_LIST, null);
export const addCouponList = (data: any) => api.create(url.ADD_COUPON_LIST, data);
export const updateCouponList = (data: any) => api.put(url.UPDATE_COUPON_LIST+ data.id, data);
export const deleteCouponList = (data: any) => api.delete(url.DELETE_COUPON_LIST+data, data);


//Age Group
export const getAgeGroups = () => api.get(url.GET_AGE_GROUPS, null);
export const getProductSizes = () => api.get(url.GET_PRODUCT_SIZES, null);
export const getSeasons = () => api.get(url.GET_SEASONS, null);
export const getProductAttributes = () => api.get(url.GET_ATTRIBUTES, null);
export const getProductAttributeValues = (data:string) => api.get(url.GET_ATTRIBUTE_VALUES+data, null);
export const getPackagings = () => api.get(url.GET_PACKAGINGS, null);



// Ecommerce
// Orders
export const getOrders = () => api.get(url.GET_ORDERS, null);
export const getOrderById = (orderId: string) => api.get(url.GET_ORDER_BY_ID+orderId, null);
export const addOrders = (data: any) => api.create(url.ADD_ORDERS, data);
export const updateOrderStatus = (data: any) => api.create(url.UPDATE_ORDERS_STATUS, data);
export const deleteOrders = (data: any) => api.delete(url.DELETE_ORDERS, { headers: { data } });
export const getOrderStatusById = (orderId: string) => api.get(url.GET_ORDER_STATUS_BY_ID+orderId, null);
export const getMonthlyOrderCounts = () => api.get(url.GET_MONTHLY_ORDER_COUNTS, null);
export const getTotalOrderCounts = () => api.get(url.GET_TOTAL_ORDER_COUNTS, null);
export const cancelOrder = (orderId: string) => api.put(url.CANCEL_ORDER+orderId, {orderId: orderId});

// Carts
export const getCartSummary = () => api.get(url.GET_CART_SUMMARY, null);
export const getCarts = () => api.get(url.GET_CARTS, null);
export const getCartById = (userId: any, businessId:any) => api.get(url.GET_CART_BY_ID+userId+"/business/"+businessId,null);


// Wishlist
export const getWishlist = () => api.get(url.GET_WISHLIST, null);
export const getWishlistById = (userId: any, businessId:any) => api.get(url.GET_WISHLIST_BY_ID+userId+"/business/"+businessId,null);


// Wishlist
export const getUserProductHistory = () => api.get(url.GET_USER_PRODUCT_HISTORY, null);
export const getUserProductHistoryById = (userId: any) => api.get(url.GET_USER_PRODUCT_HISTORY_BY_ID+userId,null);



// Sellers
export const getSellers = () => api.get(url.GET_SELLERS, null);
export const addSellers = (data: any) => api.create(url.ADD_SELLERS, data);
export const updateSellers = (data: any) => api.update(url.UPDATE_SELLERS, data);
export const deleteSellers = (data: any) => api.delete(url.DELETE_SELLERS, { headers: { data } });

// Products
export const getProductList = () => api.get(url.GET_PRODUCT_LIST, null);
export const getProductById = (data:string) => api.get(url.GET_PRODUCT+data, null);
export const addProduct = (data: any) => api.create(url.ADD_PRODUCT, data);
export const updateProduct = (data: any) => api.put(url.UPDATE_PRODUCT+data.id, data);
export const deleteProduct = (data: any) => api.delete(url.DELETE_PRODUCT+data,  data);
export const uploadProductImages = (data: any) => api.createWithFormData(url.POST_PRODUCT_IMAGE, data);
export const addProductImages = (data: any) => api.create(url.ADD_PRODUCT_IMAGE, data);
export const deleteProductImages = (data: any) => api.delete(url.DELETE_PRODUCT_IMAGE+data, data);
export const getProductImages = (data: any) => api.get(url.GET_PRODUCT_IMAGES+ data, null);


// Product Variants
export const getProductVariants = () => api.get(url.GET_PRODUCT_VARIANTS, null);
export const getProductVariantById = (data:string) => api.get(url.GET_PRODUCT_VARIANT+data, null);
export const addProductVariant = (data: any) => api.create(url.ADD_PRODUCT_VARIANT, data);
export const updateProductVariant = (data: any) => api.put(url.UPDATE_PRODUCT_VARIANT+data.id, data);
export const deleteProductVariant = (data: any) => api.delete(url.DELETE_PRODUCT_VARIANT+data.id,data);


// Grid View
export const getProductGrid = () => api.get(url.GET_PRODUCT_GRID, null);
export const addProductGrid = (data: any) => api.create(url.ADD_PRODUCT_GRID, data);
export const updateProductGrid = (data: any) => api.update(url.UPDATE_PRODUCT_GRID, data);
export const deleteProductGrid = (data: any) => api.delete(url.DELETE_PRODUCT_GRID, { headers: { data } });

// Overview
export const getReview = () => api.get(url.GET_REVIEW, null);
export const addReview = (data: any) => api.create(url.ADD_REVIEW, data);
export const updateReview = (data: any) => api.update(url.UPDATE_REVIEW, data);
export const deleteReview = (data: any) => api.delete(url.DELETE_REVIEW, { headers: { data } });

// HR Management
// Employee List
export const getEmployee = () => api.get(url.GET_EMPLOYEE, null);
export const addEmployee = (data: any) => api.create(url.ADD_EMPLOYEE, data);
export const updateEmployee = (data: any) => api.update(url.UPDATE_EMPLOYEE, data);
export const deleteEmployee = (data: any) => api.delete(url.DELETE_EMPLOYEE, { headers: { data } });

// Holidays
export const getHolidays = () => api.get(url.GET_HOLIDAYS, null);
export const addHolidays = (data: any) => api.create(url.ADD_HOLIDAYS, data);
export const updateHolidays = (data: any) => api.update(url.UPDATE_HOLIDAYS, data);
export const deleteHolidays = (data: any) => api.delete(url.DELETE_HOLIDAYS, { headers: { data } });

// Leaves Manage

// Leave Manage (Employee)
export const getLeaveManageEmployee = () => api.get(url.GET_LEAVE_MANAGE_EMPLOYEE, null);

// Leave Manage (HR)
export const getLeaveManageHR = () => api.get(url.GET_LEAVE_MANAGE_HR, null);
export const addLeaveManageHR = (data: any) => api.create(url.ADD_LEAVE_MANAGE_HR, data);
export const updateLeaveManageHR = (data: any) => api.update(url.UPDATE_LEAVE_MANAGE_HR, data);
export const deleteLeaveManageHR = (data: any) => api.delete(url.DELETE_LEAVE_MANAGE_HR, { headers: { data } });

// Attendance
// Attendance (HR)
export const getAttendance = () => api.get(url.GET_ATTENDANCE, null);

// Main Attendance
export const getMainAttendance = () => api.get(url.GET_MAIN_ATTENDANCE, null);

// Departments
export const getDepartments = () => api.get(url.GET_DEPARTMENTS, null);
export const addDepartments = (data: any) => api.create(url.ADD_DEPARTMENTS, data);
export const updateDepartments = (data: any) => api.update(url.UPDATE_DEPARTMENTS, data);
export const deleteDepartments = (data: any) => api.delete(url.DELETE_DEPARTMENTS, { headers: { data } });

// Sales
// Estimates
export const getEstimates = () => api.get(url.GET_ESTIMATES, null);
export const addEstimates = (data: any) => api.create(url.ADD_ESTIMATES, data);
export const updateEstimates = (data: any) => api.update(url.UPDATE_ESTIMATES, data);
export const deleteEstimates = (data: any) => api.delete(url.DELETE_ESTIMATES, { headers: { data } });

// Payments
export const getPayments = () => api.get(url.GET_PAYMENTS, null);

// Expenses
export const getExpenses = () => api.get(url.GET_EXPENSES, null);
export const addExpenses = (data: any) => api.create(url.ADD_EXPENSES, data);
export const updateExpenses = (data: any) => api.update(url.UPDATE_EXPENSES, data);
export const deleteExpenses = (data: any) => api.delete(url.DELETE_EXPENSES, { headers: { data } });

// Payroll
// Employee Salary
export const getEmployeeSalary = () => api.get(url.GET_EMPLOYEE_SALARY, null);
export const addEmployeeSalary = (data: any) => api.create(url.ADD_EMPLOYEE_SALARY, data);
export const updateEmployeeSalary = (data: any) => api.update(url.UPDATE_EMPLOYEE_SALARY, data);
export const deleteEmployeeSalary = (data: any) => api.delete(url.DELETE_EMPLOYEE_SALARY, { headers: { data } });

// Notes
export const getNotes = () => api.get(url.GET_NOTES, null);
export const addNotes = (data: any) => api.create(url.ADD_NOTES, data);
export const updateNotes = (data: any) => api.update(url.UPDATE_NOTES, data);
export const deleteNotes = (data: any) => api.delete(url.DELETE_NOTES, { headers: { data } });

// Social
// Friends
export const getSocialFriends = () => api.get(url.GET_SOCIAL_FRIENDS, null);

// Events
export const getSocialEvent = () => api.get(url.GET_SOCIAL_EVENTS, null);
export const addSocialEvent = (data: any) => api.create(url.ADD_SOCIAL_EVENTS, data);
export const updateSocialEvent = (data: any) => api.update(url.UPDATE_SOCIAL_EVENTS, data);
export const deleteSocialEvent = (data: any) => api.delete(url.DELETE_SOCIAL_EVENTS, { headers: { data } });

// Invoices
export const getInvoiceList = () => api.get(url.GET_INVOICE_LIST, null);

// Users
// List View
export const getUserList = () => api.get(url.GET_USER_LIST, null);
export const addUserList = (data: any) => api.create(url.ADD_USER_LIST, data);
export const updateUserList = (data: any) => api.update(url.UPDATE_USER_LIST, data);
export const deleteUserList = (user: any) => api.delete(url.DELETE_USER_LIST, { headers: { user } });

// Grid View
export const getUserGrid = () => api.get(url.GET_USER_GRID, null);
export const addUserGrid = (data: any) => api.create(url.ADD_USER_GRID, data);
export const updateUserGrid = (data: any) => api.update(url.UPDATE_USER_GRID, data);
export const deleteUserGrid = (user: any) => api.delete(url.DELETE_USER_GRID, { headers: { user } });


//ATTRIBUTE
export const getAttributeList = () => api.get(url.GET_ATTRIBUTE_LIST, null);


//ATTRIBUTE VALUES
export const getAttribute = () => api.get(url.GET_ATTRIBUTE_LIST, null);
export const addAttributeList = (data: any) => api.create(url.ADD_ATTRIBUTE_LIST, data);
export const updateAttributeList = (data: any) => api.put(url.UPDATE_ATTRIBUTE_LIST+ data.id, data);
export const deleteAttributeList = (data: any) => api.delete(url.DELETE_ATTRIBUTE_LIST+data, data);



//ATTRIBUTE VALUES
export const getAttributeValue = () => api.get(url.GET_ATTRIBUTE_VALUE_LIST, null);
export const addAttributeValue = (data: any) => api.create(url.ADD_ATTRIBUTE_VALUE_LIST, data);
export const updateAttributeValue = (data: any) => api.put(url.UPDATE_ATTRIBUTE_VALUE_LIST+ data.id, data);
export const deleteAttributeValue = (data: any) => api.delete(url.DELETE_ATTRIBUTE_VALUE_LIST+data, data);

//PRODUCT SIZE
export const getProductSize = () => api.get(url.GET_PRODUCT_SIZE, null);
export const addProductSize = (data: any) => api.create(url.ADD_PRODUCT_SIZE, data);
export const updateProductSize = (data: any) => api.put(url.UPDATE_PRODUCT_SIZE+ data.id, data);
export const deleteProductSize = (data: any) => api.delete(url.DELETE_PRODUCT_SIZE+data, data);

//DISTRICT list
export const getDistrictList = () => api.get(url.GET_DISTRICT_LIST, null);
export const addDistrictList = (data: any) => api.create(url.ADD_DISTRICT_LIST, data);
export const updateDistrictList = (data: any) => api.put(url.UPDATE_DISTRICT_LIST+ data.id, data);
export const deleteDistrictList = (data: any) => api.delete(url.DELETE_DISTRICT_LIST+data, data);

//STATE 
export const getStateList = () => api.get(url.GET_STATE_LIST, null);

//CITY
export const getCityList = () => api.get(url.GET_CITY_LIST, null);
export const addCityList = (data: any) => api.create(url.ADD_CITY_LIST, data);
export const updateCityList = (data: any) => api.put(url.UPDATE_CITY_LIST+ data.id, data);
export const deleteCityList = (data: any) => api.delete(url.DELETE_CITY_LIST+data, data);

// User permisisons
export const getUserPermissions = (roleName:number) => api.get(url.GET_USER_PERMISSIONS+ roleName, null);

// Menus
export const getMenus = () => api.get(url.GET_MENUS, null);
export const addMenu = (data: any) => api.create(url.ADD_MENU, data);

export const addRoleMenu = (data: any) => api.create(url.ADD_ROLE_MENU, data);
export const getMenusByRole = (data: any) => api.get(url.GET_MENUS_BY_ROLE+data, null);


// unverified docs
export const getUnverifiedDocs = () => api.get(url.GET_UNVERIFIED_DOCS, null);
export const getUnverifiedBusiness = () => api.get(url.GET_UNVERIFIED_BUSINESS, null);
export const getBusinessById = (businessId:string) => api.get(url.GET_BUSINESS_INFO+businessId, null);
export const getBusinessDocs = (businessId:string) => api.get(url.GET_BUSINESS_DOCS+businessId, null);


export const updateBusiness = (data: any) => api.put(url.UPDATE_BUSINESS_INFO+data.id, data);
export const updateDocStatusByBusiness = (data: any) => api.put(url.UPDATE_DOC_STATUS_BY_BUSINESS+data.id, data);
export const updateDocStatus = (data: any) => api.put(url.UPDATE_DOC_STATUS+data.id, data);

