import Modal from "Common/Components/Modal";
import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Select, { SelectInstance } from "react-select";
import {
  updateDocStatus,
  getBusinessById,
  getStates,
  getDistricts,
  getCities,
} from "../../../helpers/backend_helper";
import { Common } from "helpers/common";
import { ChevronDown, ChevronUp, Loader2 } from "lucide-react";
import { PendingBusinessType } from "types/PendingBusinessType";
import { BusinessType } from "types/BusinessType";
import { RegionType } from "types/RegionType";
import Collapse from "Common/Components/Collapse/Collapse";
type Props = { onHide: () => void; data: PendingBusinessType; onSave: (result: any) => void };

export const UpdateDocStatus: React.FC<Props> = ({ onHide, data, onSave }) => {
  const [roles, setRoles] = useState<any[]>([]);
  const [businessInfo, setBusinessInfo] = useState<BusinessType>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const commonHelper = new Common();
  const districtRef = useRef<SelectInstance<RegionType> | null>(null)
  const cityRef = useRef<SelectInstance<RegionType> | null>(null)
  const headerClass = 'flex items-center w-full p-3 mb-2 text-left card collapsible-header group/item rounded-t-md dark:bg-zink-600';

  const [states, setStates] = useState<RegionType[]>([]);
  const [districts, setDistricts] = useState<RegionType[]>([]);
  const [cities, setCities] = useState<RegionType[]>([]);

  const [selectedFiles, setSelectedFiles] = React.useState<any>([])
  const [selectedState, setSelectedState] = useState<any>();
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();

  useEffect(() => {
    if (businessInfo?.id) {
      console.log(businessInfo);
    }
    loadBusinessInfo();
  }, []);

  useEffect(() => {
    if (businessInfo?.id) {
      console.log('2nd', businessInfo);
      loadStates();
    }
  }, [businessInfo]);

  const loadStates = async () => {
    if (states.length === 0) {

      let response = await getStates();
      console.log('states', response)

      if (response.success) {
        const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
        setStates(items);
        console.log('businessInfo?.stateId', businessInfo?.stateId);
        if (businessInfo?.stateId) {
          setSelectedState(items?.find(i => i.value == businessInfo?.stateId));
        }
      }

      if (businessInfo?.id) {
        await loadDistrict(businessInfo.stateId.toString());
        await loadCities(businessInfo.districtId.toString());
      }
    }
  }
  const loadDistrict = async (stateId: string) => {
    if (!stateId)
      return;

    var params = 'value=' + stateId;
    console.log(params);
    try {
      let response = await getDistricts(params)
      console.log('districts', response)

      if (response.success) {
        const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
        setDistricts(items);
        if (businessInfo?.districtId) {
          setSelectedDistrict(items?.find(i => i.value == businessInfo?.districtId));
        }
      }
    } catch (err) {
      console.log(err);
      commonHelper.showErrorMessage('No district found for selected state.')
    }
  }

  const loadCities = async (districtId: string) => {
    if (!districtId)
      return;

    var params = 'value=' + districtId;
    try {

      let response = await getCities(params)
      console.log('cities', response)

      if (response.success) {
        const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
        setCities(items);
        if (businessInfo?.cityId) {
          setSelectedCity(items?.find(i => i.value == businessInfo?.cityId));
        }
      }
    } catch (err) {
      console.log(err);
      commonHelper.showErrorMessage('No city found for selected district.')
    }
  }

  const handleStateSelection = async (item: any) => {
    districtRef?.current?.clearValue();
    setSelectedState(item);
    setDistricts([]);
    setSelectedDistrict({});
    validation.setFieldValue(
      'stateId',
      item.value
    );

    loadDistrict(item.value)
  }

  const handleDistrictSelection = async (item: any) => {
    if (item) {
      cityRef?.current?.clearValue();
      setSelectedDistrict(item);
      setSelectedCity({} as RegionType);
      setCities([]);
      validation.setFieldValue(
        'districtId',
        item.value
      )
      loadCities(item.value);
    }
  }

  const handleCitySelection = (item: any) => {
    setSelectedCity(item);

    if (item) {
      console.log('selected state', item.value)
      validation.setFieldValue('cityId', item.value);
    }
  }


  async function loadBusinessInfo() {
    try {
      const response = await getBusinessById(data?.businessId.toString() ?? '0'); 
      const responseData = response.data as BusinessType[];
      setBusinessInfo(responseData[0]);
      console.log(responseData[0]);

    } catch (error) {
      console.error("Error loading roles", error);
    }
  }



  // validation for Business Information
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (businessInfo && businessInfo?.id) || "0",
      // documentRejectedReason: (businessInfo && businessInfo?.documentRejectedReason) || "",
      // documentVerified: true,

      userId: (businessInfo && businessInfo?.userId) || "0",
      businessName: (businessInfo && businessInfo?.businessName) || "",
      gstNo: (businessInfo && businessInfo?.gstNo) || "",
      ownerName: (businessInfo && businessInfo?.ownerName) || "",
      businessEstablishmentYear: (businessInfo && businessInfo?.businessEstablishmentYear) || "",
      businessType: (businessInfo && businessInfo?.businessType) || "",
      addressLine1: (businessInfo && businessInfo?.addressLine1) || "",
      addressLine2: (businessInfo && businessInfo?.addressLine2) || "",
      landmark: (businessInfo && businessInfo?.landmark) || "",
      stateId: (businessInfo && businessInfo?.stateId) || "",
      districtId: (businessInfo && businessInfo?.districtId) || "",
      cityId: (businessInfo && businessInfo?.cityId) || "",
      cityName: (businessInfo && businessInfo?.cityName) || "",
      countryId: (businessInfo && businessInfo?.countryId) || 1,
      pinCode: (businessInfo && businessInfo?.pinCode) || "",
      businessLogo: (businessInfo && businessInfo?.businessLogo) || "",
      businessDescription: (businessInfo && businessInfo?.businessDescription) || "",
      businessTagLine: (businessInfo && businessInfo?.businessTagLine) || "",
      contactPerson: (businessInfo && businessInfo?.contactPerson) || "",
      contactPhone: (businessInfo && businessInfo?.contactPhone) || "",
      contactEmail: (businessInfo && businessInfo?.contactEmail) || "",
      website: (businessInfo && businessInfo?.website) || "",
      docStatus: (businessInfo && businessInfo?.docStatus) || "",
    },
    validationSchema: Yup.object({
      documentRejectedReason: Yup.string(),
    }),

    onSubmit: async (values: any) => {
      setIsSaving(true);
      console.log(values);
      try {
        var result = await updateDocStatus(values);

        commonHelper.showSuccessMessage(`Business info has been successfully updated.`);
        console.log(result.data);
        onSave(result.data);
      } catch (error: any) {
        console.error("API-Error", error.response.data);
        commonHelper.showErrorMessage(error.response.data.message);
        setIsSaving(false);
      }
    },
  });




  // validation for Business Docs
  const validationBDoc: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (data && data?.id) || "0",
      documentRejectedReason: (data && data?.documentRejectedReason) || "",
      documentVerified: true,
    },
    validationSchema: Yup.object({
      documentRejectedReason: Yup.string(),
    }),

    onSubmit: async (values: any) => {
      setIsSaving(true);
      console.log(values);
      try {
        var result = await updateDocStatus(values);

        commonHelper.showSuccessMessage(`Business info has been successfully updated.`);
        console.log(result.data);
        onSave(result.data);
      } catch (error: any) {
        console.error("API-Error", error.response.data);
        commonHelper.showErrorMessage(error.response.data.message);
        setIsSaving(false);
      }
    },
  });


  return (
    <React.Fragment>
      <Modal.Header
        className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
        closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
      >
        <Modal.Title className="text-16">
          {"Update Status"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <div className="card">
          <Collapse>
            <Collapse.Trigger className={`${headerClass}  ${businessInfo?.cityId != null ? 'bg-green-300' : 'bg-red-300'}`}>
              <h4 className="text-15">Business Information</h4>
              <div className="ltr:ml-auto rtl:mr-auto shrink-0">
                <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
              </div>
            </Collapse.Trigger>
            <Collapse.Content className="collapsible-content card">
              <div className="card-body">

                <form action="#!" onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}>
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                    <div className="xl:col-span-4">
                      <label htmlFor="businessName" className="text-base font-medium">Business Name<span className='redColor'>*</span></label>
                      <input type="text" id="businessName"
                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Business Name"
                        name="businessName"

                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.businessName || ""}
                      />
                      {validation.touched.businessName && validation.errors.businessName ? (
                        <p className="text-red-400">{validation.errors.nabusinessNameme}</p>
                      ) : null}
                    </div>


                    <div className="xl:col-span-4">
                      <label htmlFor="ownerName" className="inline-block mb-1 text-base font-medium">Owner Name<span className='redColor'>*</span></label>
                      <input id="ownerName" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Owner Name"
                        name="ownerName"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ownerName || ""}
                      />
                      {validation.touched.ownerName && validation.errors.ownerName ? (
                        <p className="text-red-400">{validation.errors.ownerName}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="gstNo" className="inline-block mb-1 text-base font-medium">GST No<span className='redColor'>*</span></label>
                      <textarea id="gstNo"
                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="GST No"
                        name="gstNo"
                        rows={1}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gstNo || ""}
                      />
                      {validation.touched.gstNo && validation.errors.gstNo ? (
                        <p className="text-red-400">{validation.errors.gstNo}</p>
                      ) : null}
                    </div>


                    <div className="xl:col-span-6">
                      <label htmlFor="addressLine1" className="inline-block mb-1 text-base font-medium">Address 1<span className='redColor'>*</span></label>
                      <input id="addressLine1" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Address1"
                        name="addressLine1"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addressLine1 || ""}
                      />
                      {validation.touched.addressLine1 && validation.errors.addressLine1 ? (
                        <p className="text-red-400">{validation.errors.addressLine1}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-6">
                      <label htmlFor="addressLine2" className="inline-block mb-1 text-base font-medium">Address 2<span className='redColor'>*</span></label>
                      <input id="addressLine2" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Address2"
                        name="addressLine2"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.addressLine2 || ""}
                      />
                      {validation.touched.addressLine2 && validation.errors.addressLine2 ? (
                        <p className="text-red-400">{validation.errors.addressLine2}</p>
                      ) : null}
                    </div>

                    <div className="xl:col-span-4">
                      <label htmlFor="stateId" className="inline-block mb-2 text-base font-medium">State<span className='redColor'>*</span></label>

                      <Select
                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        defaultValue={selectedState}
                        options={states}
                        isSearchable={true} // To disable search
                        name="stateId"
                        id="stateId"
                        value={selectedState}
                        onChange={e => { handleStateSelection(e) }}
                      />


                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="districtId" className="inline-block mb-2 text-base font-medium">District<span className='redColor'>*</span></label>
                      <Select
                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        options={districts}
                        ref={districtRef}
                        isSearchable={true} // To disable search
                        name="districtId"
                        id="districtId"
                        value={selectedDistrict}
                        onChange={e => { handleDistrictSelection(e) }}
                      />


                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="cityId" className="inline-block mb-2 text-base font-medium">City<span className='redColor'>*</span></label>
                      <Select
                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        options={cities}
                        ref={cityRef}
                        isSearchable={true} // To disable search
                        name="cityId"
                        id="cityId"
                        value={selectedCity}
                        onChange={e => { handleCitySelection(e) }}
                      />

                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="pinCode" className="inline-block text-base font-medium">Pin Code<span className='redColor'>*</span></label>
                      <input id="pinCode" type="number" maxLength={1} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Pin Code"
                        name="pinCode"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.pinCode || ""}
                      />
                      {validation.touched.pinCode && validation.errors.pinCode ? (
                        <p className="text-red-400">{validation.errors.pinCode}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="contactPerson" className="inline-block mb-1 text-base font-medium">Contact Person<span className='redColor'>*</span></label>
                      <input id="contactPerson" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        placeholder="Contact Person"
                        name="contactPerson"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactPerson || ""}
                      />
                      {validation.touched.contactPerson && validation.errors.contactPerson ? (
                        <p className="text-red-400">{validation.errors.contactPerson}</p>
                      ) : null}
                    </div>
                    <div className="xl:col-span-4">
                      <label htmlFor="contactPhone" className="inline-block text-base font-medium">Phone NO.<span className='redColor'>*</span></label>
                      <input id="contactPhone" type="number" maxLength={1} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="phone"
                        name="contactPhone"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.contactPhone || ""}
                      />
                      {validation.touched.contactPhone && validation.errors.contactPhone ? (
                        <p className="text-red-400">{validation.errors.contactPhone}</p>
                      ) : null}
                    </div>

                    <div className="xl:col-span-4">
                      <label htmlFor="docStatus" className="inline-block text-base font-medium">Doc Status<span className='redColor'>*</span></label>
                      <div className="flex flex-wrap gap-2">

                        <div className="flex items-center gap-2">
                          <input id="rdbDocsApproved" defaultChecked={true} data-testid="dbDocsApproved" name="docStatus" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-green-500 checked:border-green-500 dark:checked:bg-green-500 dark:checked:border-green-500" type="radio" value="" />
                          <label htmlFor="radioInline2" className="align-middle">
                            Approve Docs
                          </label>
                        </div>

                        <div className="flex items-center gap-2">
                          <input id="rdbDocsRejected" name="docStatus" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-orange-500 checked:border-orange-500 dark:checked:bg-orange-500 dark:checked:border-orange-500" type="radio" value="" />
                          <label htmlFor="radioInline3" className="align-middle">
                            Reject Docs
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-8">

                    <div className="flex justify-end gap-2 mt-4">
                      <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={onHide}>Cancel</button>

                      {!isSaving ?
                        <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                          {"Update"}
                        </button>
                        : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                          <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                          Saving...
                        </button>
                      }
                    </div>
                    </div>
                  </div>

                </form>

              </div>
            </Collapse.Content>
          </Collapse>
        </div>

        <div className="card">
          <Collapse>
            <Collapse.Trigger className={`${headerClass} ${data.status == 'Verified' ? 'bg-green-300' : 'bg-red-300'}`}>
              <h4 className="text-15">Business Documents</h4>
              <div className="ltr:ml-auto rtl:mr-auto shrink-0">
                <ChevronDown className="hidden size-4 group-[.show]/item:inline-block"></ChevronDown>
                <ChevronUp className="inline-block size-4 group-[.show]/item:hidden"></ChevronUp>
              </div>
            </Collapse.Trigger>
            <Collapse.Content className="collapsible-content card">
              <div className="card-body">
                <form
                  action="#!"
                  onSubmit={(e) => {
                    console.log('Error', validationBDoc.errors);
                    e.preventDefault();
                    validationBDoc.handleSubmit();
                    return false;
                  }}
                  style={{ height: 200 }}
                  autoComplete="off"
                >
                  <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">

                    <div className="xl:col-span-3">
                      <label
                        htmlFor="documentRejectedReason"
                        className="inline-block mb-2 text-base font-medium"
                      >
                        Status
                      </label>
                      <div className="flex flex-wrap gap-2">

                        <div className="flex items-center gap-2">
                          <input id="radioInline2" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-green-500 checked:border-green-500 dark:checked:bg-green-500 dark:checked:border-green-500" type="radio" value="" />
                          <label htmlFor="radioInline2" className="align-middle">
                            Approve
                          </label>
                        </div>

                        <div className="flex items-center gap-2">
                          <input id="radioInline3" name="InlineRadio" className="size-4 border rounded-full appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-orange-500 checked:border-orange-500 dark:checked:bg-orange-500 dark:checked:border-orange-500" type="radio" value="" />
                          <label htmlFor="radioInline3" className="align-middle">
                            Reject
                          </label>
                        </div>
                      </div>

                    </div>
                    <div className="xl:col-span-6">
                      <label
                        htmlFor="documentRejectedReason"
                        className="inline-block mb-2 text-base font-medium"
                      >
                        Status Comment
                      </label>
                      <input
                        type="text"
                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        id="documentRejectedReason"
                        name="documentRejectedReason"
                        autoComplete="hidden"
                        list="autocompleteOff"
                        placeholder="Comments"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validationBDoc.values.documentRejectedReason || ""}
                      />
                      {validationBDoc.touched.documentRejectedReason && validationBDoc.errors.documentRejectedReason ? (
                        <div id="documentRejectedReason-error" className="mt-1 text-sm text-red-500">
                          {validationBDoc.errors.documentRejectedReason}
                        </div>
                      ) : null}
                    </div>

                    <div className="xl:col-span-3">
                      <label
                        htmlFor="documentRejectedReason"
                        className="inline-block mb-2 text-base font-medium"
                      >
                        Document
                      </label>
                      <Link to={data?.documentUrl} target="_blank">
                        <img src={data?.documentUrl} style={{ width: 100, height: 100, borderRadius: 10 }} />
                      </Link>
                    </div>

                    <div className="flex justify-end xl:col-span-12">
                      <button
                        type="reset"
                        data-modal-close="addDocuments"
                        className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
                        onClick={onHide}
                      >
                        Cancel
                      </button>

                      {!isSaving ? (
                        <button
                          type="submit"
                          className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        >
                          {"Update"}
                        </button>

                      ) : (
                        <button
                          type="button"
                          disabled
                          className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        >
                          <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                          Saving...
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </Collapse.Content>
          </Collapse>
        </div>

      </Modal.Body>
    </React.Fragment>
  );
};
