import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface AuthProtectedProps {
  children: ReactNode;
}

const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {

  function setActivityTimestamp() {
    localStorage.setItem('lastActivity', Date.now().toString());
  }
  
  document.addEventListener('click', setActivityTimestamp);
  document.addEventListener('keydown', setActivityTimestamp);
  document.addEventListener('mousemove', setActivityTimestamp);
  
  function checkActivity() {
    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity) {
        const currentTime = Date.now();
        const elapsed = currentTime - parseInt(lastActivity);
        console.log(lastActivity,currentTime,elapsed);
  
        const maxInactiveTime = 1 * 60 * 1000; // 15 minutes in milliseconds
        if (elapsed > maxInactiveTime) {
            localStorage.clear();
            console.log('Local storage cleared due to inactivity.');
        }
    }
  }
  
  // Check every minute
  setInterval(checkActivity, 60 * 1000);
  
  document.addEventListener('DOMContentLoaded', () => {
    setActivityTimestamp(); // Set initial timestamp
    checkActivity(); // Check immediately on load
  });
  

  if (!localStorage.getItem("token")) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
